import { render, staticRenderFns } from "./app.vue?vue&type=template&id=0157c717&lang=pug&"
import script from "./app.vue?vue&type=script&lang=coffee&"
export * from "./app.vue?vue&type=script&lang=coffee&"
import style0 from "./app.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CommonError from '@/components/common/error.vue'
import CommonFlash from '@/components/common/flash.vue'
import CommonFooter from '@/components/common/footer.vue'
import ModalLauncher from '@/components/modal/launcher.vue'
import Navbar from '@/components/common/navbar.vue'
import Sidebar from '@/components/common/sidebar.vue'
import SystemNotice from '@/components/common/system_notice.vue'
import { VApp } from 'vuetify/lib/components/VApp';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {CommonError,CommonFlash,CommonFooter,ModalLauncher,Navbar,Sidebar,SystemNotice,VApp,VSpacer})
