import { render, staticRenderFns } from "./form_page.vue?vue&type=template&id=969ec82e&lang=pug&"
import script from "./form_page.vue?vue&type=script&lang=coffee&"
export * from "./form_page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DiscussionForm from '@/components/discussion/form.vue'
import DiscussionTemplateBanner from '@/components/discussion/template_banner.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {DiscussionForm,DiscussionTemplateBanner,VCard,VContainer,VMain})
