import { render, staticRenderFns } from "./form.vue?vue&type=template&id=7099d21a&lang=pug&"
import script from "./form.vue?vue&type=script&lang=coffee&"
export * from "./form.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import CommonNotifyFields from '@/components/common/notify_fields.vue'
import DismissModalButton from '@/components/common/dismiss_modal_button.vue'
import HelpLink from '@/components/common/help_link.vue'
import LmoTextarea from '@/components/lmo_textarea/lmo_textarea.vue'
import Space from '@/components/common/space.vue'
import SubmitOverlay from '@/components/common/submit_overlay.vue'
import TagsField from '@/components/tags/field.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {CommonNotifyFields,DismissModalButton,HelpLink,LmoTextarea,Space,SubmitOverlay,TagsField,VAlert,VBtn,VCardActions,VCardTitle,VCheckbox,VIcon,VRadio,VRadioGroup,VSelect,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VTextField,ValidationErrors})
