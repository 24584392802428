import { render, staticRenderFns } from "./action_dock.vue?vue&type=template&id=56c13123&lang=pug&"
import script from "./action_dock.vue?vue&type=script&lang=coffee&"
export * from "./action_dock.vue?vue&type=script&lang=coffee&"
import style0 from "./action_dock.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionButton from '@/components/common/action_button.vue'
import ActionMenu from '@/components/common/action_menu.vue'
import ReactionDisplay from '@/components/reaction/display.vue'
import ReactionInput from '@/components/reaction/input.vue'
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {ActionButton,ActionMenu,ReactionDisplay,ReactionInput,VSpacer})
