
import Session        from '@/shared/services/session'
import AbilityService from '@/shared/services/ability_service'
import ThreadService from '@/shared/services/thread_service'
import { map, sortBy, filter, debounce, without, uniq, find, compact } from 'lodash'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'

import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    discussion: Object
    isPage: Boolean
    user: Object

  data: ->
    tab: 0
    upgradeUrl: AppConfig.baseUrl + 'upgrade'
    submitIsDisabled: false
    searchResults: []
    subscription: @discussion.group().parentOrSelf().subscription
    groupItems: []
    initialRecipients: []

  mounted: ->
    Records.users.fetchGroups()

    @watchRecords
      collections: ['groups', 'memberships']
      query: (records) =>
        @updateGroupItems()

  watch:
    'discussion.groupId':
      immediate: true
      handler: (groupId) ->
        @subscription = @discussion.group().parentOrSelf().subscription
        users = compact([@user]).map (u) ->
          id: u.id
          type: 'user'
          name: u.nameOrEmail()
          user: u
        @initialRecipients = []
        @initialRecipients = @initialRecipients.concat(users)
        @discussion.private = @discussion.privateDefaultValue()
        @reset = !@reset

  methods:
    submit: ->
      actionName = if @discussion.id then 'updated' else 'created'
      @discussion.save()
      .then (data) =>
        discussionKey = data.discussions[0].key
        EventBus.$emit('closeModal')
        Records.discussions.findOrFetchById(discussionKey, {}, true).then (discussion) =>
          Flash.success("discussion_form.messages.#{actionName}")
          @$router.push @urlFor(discussion)
      .catch (error) => true

    updateGroupItems: ->
      @groupItems = [{text: @$t('discussion_form.none_invite_only_thread'), value: null}].concat Session.user().groups().map (g) -> {text: g.fullName, value: g.id}

  computed:
    maxThreads: ->
      @subscription.max_threads

    threadCount: ->
      @discussion.group().parentOrSelf().orgDiscussionsCount

    maxThreadsReached: ->
      @maxThreads && @threadCount >= @maxThreads

    subscriptionActive: ->
      @subscription.active

    canStartThread: ->
      @subscriptionActive && !@maxThreadsReached

    showUpgradeMessage: ->
      !@discussion.id && !@canStartThread

    isMovingItems: ->
      @discussion.forkedEventIds.length

